//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import EmptyState from '@/components/shared/EmptyState.vue'

export default {
   name: "AllStatistics",
        components: { EmptyState },
  data() {
      return {
      isCheck: false,
      isLoading: false,
      total_pages: 0,
      total_rows: 0,
      statisticsList: [],
      statisticPushsList: [],
      query: {
        Filter: [],
        PageNumber: 1,
        PageSize: process.env.VUE_APP_Default_Page_Size,
      },
      Filter: {},
    };
  },

        mounted() {
    let self = this;
      self.load();
  },
        methods: {
            load() {
                let self = this;
                if (!self.isLoading && !self.isCheck) {
                    self.isLoading = true;
                    self.axios
                        .post(process.env.VUE_APP_APIEndPoint + "Statistics/PublicGetAll", self.query)
                        .then(function (res) {
                            self.statisticPushsList = res.data.Data;
                            self.statisticsList.push(...self.statisticPushsList);
                            self.query.PageNumber = res.data.PageNumber;
                            self.query.PageSize = res.data.PageSize;
                            self.total_rows = res.data.Total;
                            self.total_pages = Math.ceil(self.total_rows / res.data.PageSize);
                            if (self.total_pages > 0 && self.query.PageNumber <= 1) {
                                setTimeout(function () { }, 100);
                            }
                            self.isCheck = false;
                            if (self.total_pages <= self.query.PageNumber) self.isCheck = true;
                        }).finally(() => self.isLoading = false);
                }
            },
            Scroll() {
                let self = this;
                self.query.PageNumber++;
                self.load();
            },
            handleScroll(event) {
                let infiniteList = document.getElementById("infinite-list");
                if (infiniteList) {
                    let listPosition = infiniteList.offsetTop + infiniteList.offsetHeight;

                    if (window.scrollY >= listPosition - 600) {
                        this.Scroll();
                    }
                }
            }
        },

        
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
};
